(function() {
  function updateSignupURL(){
    if( $('.filing-link').length > 0 ) {
      var url = $('.filing-link').attr('href');
      var packageSelected = $('.package-box.selected').attr('data-package');
      var reExp = /p=[a-zA-Z]+/;
      var new_url = url.replace(reExp, "p=" + packageSelected);
      $('.filing-link').attr('href', new_url);

      if( $('.the-price').length > 0 ) {
        var thePrice = $('.package-box.selected .the-price').text();
        $('.filing-price').text(thePrice + ' Total');
      }
    }
  }

  $(function(){
    $('.mobile-package-table-container .switcher div').on('click', function(){
        if ($(this).hasClass('active')) {

        }
        else {
          var packageName = $(this).find('.package-name').text();
          $('.mobile-package-table-container .switcher div').removeClass('active');
          $(this).addClass('active');
          $('.mobile-packs').hide();
          if ( packageName == 'Basic') {
            $('.basic-pack-mobile').show();
          }
          else if ( packageName == 'Deluxe') {
            $('.deluxe-pack-mobile').show();
          }
          else if ( packageName == 'Premium') {
            $('.premium-pack-mobile').show();
          }
        }
    });

    $('.package-table-help').on('click', function(){
          $('.package-overlay').fadeIn(500);
          $('.help-overlay h4').html($(this).attr('data-help-head'));
          $('.help-overlay p').html($(this).attr('data-help-body'));
          var screenTop = $(document).scrollTop();
          screenTop = screenTop + 20;
          $('.help-overlay').css('top', screenTop);
    });

    $('.package-help').on('click', function(){
      $('.package-overlay').fadeIn(500);
      $('.package-content').css('visibility', 'visible').addClass('down');
    });

    $('.exit-b, .package-overlay').on('click', function(){
      $('.package-overlay').fadeOut(500);
      $('.package-content').removeClass('down');
      $('.help-overlay').css('top', '-1000px');
    });
    $('.package-content').on('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function() {
      // executed after down class is removed and slide up transition is complete
      // hiding so that the link-button on the package content area is not selected by the :focusable selector
      if (!$(this).hasClass('down')) {
        $(this).css('visibility', 'hidden');
      }
    });

    $('.package-overlay').on('click', function(){
      $('.help-overlay').removeClass('down');
      $('.basic-full').css('top', '-1000px');
      $('.premium-full').css('top', '-1000px');
      $('.deluxe-full').css('top', '-1000px');
    });

    $('.exit-help-b, .help-overlay .button').on('click', function(){
      $('.package-overlay').fadeOut(500);
      $('.help-overlay').css('top', '-1000px');
    });

    $('.full-package-summary-trigger-basic').on('click', function(){
      $('.package-overlay').fadeIn(500);
      var screenTop = $(document).scrollTop();
      screenTop = screenTop + 20;
      $('.basic-full').css('top', screenTop);
    });
    $('.full-package-summary-trigger-premium').on('click', function(){
      $('.package-overlay').fadeIn(500);
      var screenTop = $(document).scrollTop();
      screenTop = screenTop + 20;
      $('.premium-full').css('top', screenTop);
    });
    $('.full-package-summary-trigger-deluxe').on('click', function(){
      $('.package-overlay').fadeIn(500);
      var screenTop = $(document).scrollTop();
      screenTop = screenTop + 20;
      $('.deluxe-full').css('top', screenTop);
    });

    $('.exit-b2').on('click', function(){
      $('.package-overlay').fadeOut(500);
      $('.basic-full').css('top', '-1000px');
      $('.premium-full').css('top', '-1000px');
      $('.deluxe-full').css('top', '-1000px');
    });



    var allPanels = $('.h-accord .h-accord-body').hide();
    $('.h-accord:first-of-type .h-accord-body').show();
    $('.h-accord:first-of-type i').addClass('rotate-180');
    $('.h-accord .h-accord-head').click(function() {
      allPanels.slideUp();
      $(this).next().slideDown();
      $('.h-accord i').removeClass('rotate-180');
      $(this).find('i').addClass('rotate-180');
      return false;
    });

    $('.common-questions-trigger').on('click', function() {
      var screenTop = $(document).scrollTop();
      screenTop = screenTop + 20;
      $('.modal-content').css('top', screenTop);
      $('.package-overlay').fadeIn(500);
      $('.modal-content').fadeIn(500);
    });

    $('.package-overlay, .modal-content i').on('click', function() {
      $('.package-overlay').fadeOut(500);
      $('.modal-content').fadeOut(500);
      $('.help-overlay').css('top', '-1000px');
    });

    $('.the-bar .left-side').on('click', function(){
      if (!$(this).hasClass('active')) {
        $('.the-bar .right-side').removeClass('active');
        $(this).addClass('active');
        $('.right-side-header').hide();
        $('.left-side-header').show();
        $('.left-side-content').show();
        $('.right-side-content').hide();
      }
    });
    $('.the-bar .right-side').on('click', function(){
      if (!$(this).hasClass('active')) {
        $('.the-bar .left-side').removeClass('active');
        $(this).addClass('active');
        $('.right-side-header').show();
        $('.left-side-header').hide();
        $('.left-side-content').hide();
        $('.right-side-content').show();
      }
    });

    $('.learn-more-in-state .learn-more-heading').on('click', function(){
      $(this).parent().find('.learn-more-choices').slideToggle();
      $(this).parent().toggleClass('open');
    });

    $('header nav .toggle a').on('click', function(){
      $('body').find('.main-nav').slideToggle();
    });

    $('.faq-question .question').on('click', function(){
      $(this).next().slideToggle(250);
      $(this).toggleClass('open');
    });

    var reviews = $('.people-trust .review-box').length;

    if( reviews > 0) {
      var x = 1;
      var running = true;
      function reviewFade(){
        if(running){
          $('.people-trust .review-box[data-review="' + x + '"]').fadeIn(500, function(){
            $(this).delay(3000).fadeOut(500, function(){
              x++;
              if(x > reviews){
                x = 1;
              }
              reviewFade();
            });
          });
        }
      }

      reviewFade();

      $('.people-trust .people img').on('click', function(){
        running = false;
        $('.people-trust .people .current').removeClass('current');
        $(this).parent().addClass('current');
        var reviewNumber = $(this).parents('a').attr('data-review');
        $('.review-box').hide();
        $('.review-box[data-review="' + reviewNumber + '"]').show();
      })
    }

    updateSignupURL();
    $('.package-box').on('click', function(){
      if (!$(this).hasClass('selected')) {
        $('.package-box').removeClass('selected');
        $('.package-footer').slideUp();
        $(this).addClass('selected');
        $(this).find('.package-footer').slideDown();
        updateSignupURL();
      }
    });

    $('.accordion .head').on('click', function(){
      $(this).parent().toggleClass('open');
      $(this).next().slideToggle();
    });

    $('.read-more').on('click', function(){
      $(this).hide();
      $(this).parent().next().slideDown();
    });

    $('[name="located"]').on('change', function(){
      $('.more-state-info form').submit();
    });

    var isAnimating = false;

    $('.single-accord:first-of-type .b').addClass('current');
    $('.single-accord:first-of-type .h i').addClass('rotate-icon');

    $('.single-accord .h').on('click', function(e){
      if (!isAnimating){
        isAnimating = true;
        console.log('here');
        if ( $(this).next().hasClass('current')) {
          $('.single-accord .h i').removeClass('rotate-icon');
          $(this).next().removeClass('current').slideUp(function(){
            isAnimating = false;
          });
        }
        else {
          $('.single-accord .b').slideUp().removeClass('current');
          $('.single-accord .h i').removeClass('rotate-icon');
          $(this).next().addClass('current').slideToggle(function(){
            isAnimating = false;
          });
          $(this).find('i').addClass('rotate-icon');

        }
      }
    });

    //grab html generated for the sub nav, and add it into the main nav with a mobile only visibility class since so many different navs on mobile is extremely confusing.
    var navLi = $('.sub-nav ul').html();
    var $mainNav = $('.main-nav');
    $mainNav.append(navLi);

  });
})($)
